export const ResCode = {
  SERVER_ERROR: { code: 500 }, // Do nothing on this error
  TEST_ENDED_ERROR: { code: 1165 }, // Show test ended screen
  TEST_NOT_FOUND_ERROR: { code: 1158 },
  TEST_ANSWER_CLOSED: { code: 1164 },
};

// Codes for handling logout
export const AccountErrCodes = {
  INVALID_TOKEN_FIREBASE: 1000,
  ACCOUNT_INACTIVE: 1148,
  USER_NOT_FOUND_DB: 1195,
  LICENSE_EXPIRED: 1196,
  INVALID_TOKEN_JWT: 1197,
};

// Firebase login errors
export const FirebaseLoginErrCodes = {
  INVALID_PASSWORD: {
    code: "auth/wrong-password",
    message:
      "Password incorrect. In Case you have forgotten your password you can reset password or login using Google",
  },
  USER_NOT_FOUND: {
    code: "auth/user-not-found",
    message:
      "User not found. Please check your mailbox and ensure you have activated your account using welcome email.",
  },
  NO_INTERNET_CONN: { code: "auth/network-request-failed", message: "No internet connection" },
  TO_MANY_TRIES: {
    code: "auth/too-many-requests",
    message:
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  },
};
