export const Users = {
  ADMIN: 100,
  SUPER_TEACHER: 200,
  SUB_ROLE: 300,
  INSTITUTE_ADMIN: 400,
  INSTITUTE_REPORT_VIEWER: 500,
  INSTITUTE_TEACHER: 600,
  TEACHER: 700,
  STUDENT: 800,
};
