import { useToast } from "@chakra-ui/toast";

function useApi() {
  const toast = useToast();

  return (
    api, // type: function
    handleSuccess, // type: function
    showSuccesToast = false, // type: boolean
    handleError, // type: function, For custom error message, this function should return error msg else return false.
    /* (Arrorw function should be like this,() => { Code... } not like this () => some code)*/
    showErrorToast = true, // type: boolean
    handleFinal, // type: function
  ) =>
    api
      .then(({ data }) => {
        showSuccesToast &&
          toast({
            title: data.message || "Success",
            status: "success",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
        handleSuccess && handleSuccess(data);
      })
      .catch((e) => {
        console.log(e);
        // In case of custom error message, handleError function should return custom error msg
        let tempMsg = handleError && handleError(e);
        let errMsg = typeof tempMsg === "string" ? tempMsg : e.message;

        showErrorToast &&
          toast({
            title: errMsg || "Please try again!",
            status: "error",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
      })
      .finally(() => {
        handleFinal && handleFinal();
      });
}

export default useApi;
