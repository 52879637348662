import React from "react";
import PropTypes from "prop-types";
import { Flex, Spinner } from "@chakra-ui/react";

function Loader({ height, spinnerSize = "lg", spinnerColor = "" }) {
  return (
    <Flex alignItems="center" justifyContent="center" h={height}>
      <Spinner size={spinnerSize} {...(spinnerColor ? { color: spinnerColor } : "")} />
    </Flex>
  );
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  spinnerSize: PropTypes.string,
  spinnerColor: PropTypes.string,
};

export default Loader;
