import React, { useContext, useReducer } from "react";
import PropTypes from "prop-types";

const FilterContext = React.createContext();

export function useFilterState() {
  return useContext(FilterContext);
}

function reducer(state, action) {
  switch (action.type) {
    case "classId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        subjectId: "",
        chapterId: "",
        topicId: "",
        subjects: [],
        chapters: [],
        topics: [],
      };
    }
    case "subjectId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        chapterId: "",
        topicId: "",
        chapters: [],
        topics: [],
      };
    }
    case "chapterId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        topicId: "",
        topics: [],
      };
    }
    case "topicId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "qCategoryId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "resource": {
      return {
        ...state,
        [action.resourceName]: action.payload,
      };
    }
    case "reset": {
      return {
        ...initialState,
        [action.classes]: action.payload,
      };
    }
  }
}

const initialState = {
  classId: "",
  subjectId: "",
  chapterId: "",
  topicId: "",
  qCategoryId: "",
  classes: [],
  subjects: [],
  chapters: [],
  topics: [],
  qcCategory: [],
};

export function FilterProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch,
  };
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
