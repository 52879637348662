import React, { useContext, useState, useEffect } from "react";
import { auth, emailProvider, googleProvider } from "../firebase";
import ProfileService from "Services/Auth/Profile";
import useApi from "../Hooks/useApi";
import Loader from "../Components/Shared/Loader";
import { Users } from "../Utils/Users";
import { StudentOnboardingStatus } from "../Utils/constants";
import { stringConcat } from "../Utils/common";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [instituteId, setInstituteId] = useState(null);
  const [requestCode, setRequestCode] = useState(null);
  const [showRomanClass, setShowRomanClass] = useState(false);
  const [instituteDetails, setInstituteDetails] = useState("");
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [indeptTeacherQRCodeUrl, setIndeptTeacherQRCodeUrl] = useState("");
  const [stdDetails, setStdDetails] = useState({});
  const [canResetStdDetails, setCanResetStdDetails] = useState(false); // To reset std details on successful onboarding and to prevent extra api call on login
  const [redirectRoute, setRedirectRoute] = useState(null); // Used to redirect user requested page after successful login
  const [isManualLogout, setIsManualLogout] = useState(false);

  const { STUDENT } = Users;
  const { STD_ONBOARDED } = StudentOnboardingStatus;
  const api = useApi();

  const resetValue = () => {
    setUserId(null);
    setRoleId([]);
    setInstituteId(null);
    setRequestCode(null);
    setUserDetails({});
    setInstituteDetails("");
    setShowRomanClass(false);
    setStdDetails({});
    setCanResetStdDetails(false);
    setIsLoginFailed(false);
    setLoading(false);
  };

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout(resetRedirectPage = true) {
    setIsManualLogout(resetRedirectPage);

    return auth.signOut();
  }

  function signInWithGoogle() {
    return auth.signInWithPopup(googleProvider);
  }

  function reauthenticateUser(currentPassword) {
    const user = auth.currentUser;
    const credential = emailProvider.credential(user.email, currentPassword);

    return user.reauthenticateWithCredential(credential);
  }

  function updatePassword(newPassword) {
    return auth.currentUser.updatePassword(newPassword);
  }

  // To reset user details on profile details or image update
  function updateUserDetails(data) {
    const { fname, lname, phone, imageUrl } = data;

    setUserDetails({ ...userDetails, name: stringConcat(fname, lname), phone, imageUrl });
  }

  function getProfile() {
    return api(
      ProfileService.getProfile(),
      (data) => {
        const {
          id,
          fname,
          lname,
          email,
          phone,
          roles,
          institute,
          configs,
          imageUrl,
          onboardingStatus,
        } = data.data;

        const roleIds = roles.map((r) => r.id);

        setUserDetails({ id, name: stringConcat(fname, lname), email, phone, roleIds, imageUrl });

        setRoleId(roleIds);
        setUserId(id);
        if (institute) {
          const { id, requestCode, qrCodeFileUrl } = institute;

          id && setInstituteId(id);
          requestCode && setRequestCode(requestCode);
          qrCodeFileUrl && setIndeptTeacherQRCodeUrl(qrCodeFileUrl);
          setInstituteDetails(institute);
        }

        configs && setShowRomanClass(configs.showRomanClass);

        if (roleIds.includes(STUDENT)) {
          const { id } = onboardingStatus;
          setCanResetStdDetails(id != STD_ONBOARDED);
          setStdDetails(data.data);
        }
      },
      false,
      () => {
        setIsLoginFailed(true);
        logout(false);
      },
      true,
      null,
    );
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      user ? getProfile() : resetValue();

      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    currentUser && userId && setLoading(false);
  }, [userId]);

  // Refreshing student details to get class or institute after successful onboarding
  useEffect(() => {
    if (stdDetails && canResetStdDetails) {
      const {
        onboardingStatus: { id: onboardingStatusId },
      } = stdDetails;

      onboardingStatusId == STD_ONBOARDED && getProfile();
    }
  }, [stdDetails]);

  const value = {
    currentUser,
    userId,
    userDetails,
    updateUserDetails,
    roleId,
    instituteId,
    requestCode,
    instituteDetails,
    setInstituteDetails,
    showRomanClass,
    setShowRomanClass,
    stdDetails,
    setStdDetails,
    getProfile,
    indeptTeacherQRCodeUrl,
    login,
    logout,
    signInWithGoogle,
    updatePassword,
    reauthenticateUser,
    isLoginFailed,
    redirectRoute,
    setRedirectRoute,
    isManualLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loader height="100vh" spinnerSize="xl" /> : children}
    </AuthContext.Provider>
  );
}
