import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";

export default function PublicRoute({ component: Component, title = "", ...rest }) {
  const { currentUser, userId, redirectRoute } = useAuth();
  document.title = `${title ? title + " | " : ""}${process.env.REACT_APP_WEBSITE_NAME}`;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser && userId) {
          // If logged in, Redirect to user requested page if available otherwise dashboard (default) page
          return <Redirect to={redirectRoute || "/"} />;
        }

        return <Component {...props} />;
      }}
    ></Route>
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string,
};
