import React, { createContext, useContext, useState } from "react";

const AddEditTestContext = createContext();

export function useAddEditTestContext() {
  return useContext(AddEditTestContext);
}

export function AddEditTestProvider({ children }) {
  const [testDetails, setTestDetails] = useState("");
  const [testQuestions, setTestQuestions] = useState([]);
  const [testConfigs, setTestConfigs] = useState([]);
  const [replaceRefreshQuesIds, setReplaceRefreshQuesIds] = useState([]);

  const handleReplaceQuestion = (questionId, newQuestion) => {
    let index;
    for (let i = 0; i < testQuestions.length; i++) {
      const { id } = testQuestions[i];
      if (id == questionId) {
        index = i;
        break;
      }
    }

    const { id: newId } = newQuestion;

    setTestQuestions(
      testQuestions.map((ques, idx) => {
        if (idx == index) {
          return newQuestion;
        } else {
          return ques;
        }
      }),
    );

    if (replaceRefreshQuesIds.includes(newId)) {
      setReplaceRefreshQuesIds(replaceRefreshQuesIds.filter((id) => id != newId));
    } else {
      setReplaceRefreshQuesIds([...replaceRefreshQuesIds, newId]);
    }
  };

  const reset = () => {
    setTestDetails("");
    setTestQuestions([]);
    setReplaceRefreshQuesIds([]);
  };

  const value = {
    testDetails,
    setTestDetails,
    testQuestions,
    setTestQuestions,
    testConfigs,
    setTestConfigs,
    handleReplaceQuestion,
    replaceRefreshQuesIds,
    setReplaceRefreshQuesIds,
    reset,
  };

  return <AddEditTestContext.Provider value={value}>{children}</AddEditTestContext.Provider>;
}
