import moment from "moment";
import { UserStatus } from "./constants";

export const headerFilter = (tableHeaders, roleId) => {
  return tableHeaders.filter((header) => header.roleIds.includes(roleId));
};

export const matchYoutubeUrl = (url) => {
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url?.match(p)) {
    return true;
  }
  return false;
};

export const fileNameTruncater = (name = "", size) => {
  return name.length > size ? `${name.substr(0, size)}...${name.slice(-4)}` : name;
};

export const truncater = (name = "", size) => {
  return name?.length > size ? `${name.substr(0, size)}...` : name;
};

export const getUserNameIntials = (name = "") => {
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const downloadFile = (data, fileName = "file") => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  let extension = "";

  // Add MIME type and their respective Extension
  switch (data.type) {
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      extension = ".xlsx";
      break;
    case "application/vnd.ms-excel":
      extension = ".xls";
      break;
    case "application/pdf":
      extension = ".pdf";
      break;
    case "image/png":
      extension = ".png";
      break;
    case "image/jpeg":
      extension = ".jpeg";
      break;
    case "video/mp4":
      extension = ".mp4";
      break;
    case "audio/mpeg":
      extension = ".mp3";
      break;
  }

  const link = document.createElement("a");

  link.href = downloadUrl;
  link.target = "_blank";

  link.setAttribute("download", fileName + extension);

  document.body.appendChild(link);

  link.click();

  link.remove();
};

export const checkStringLength = (str) => {
  return str.trim().length;
};

export const generateDurationDiffText = (
  minutes,
  usePluralConversion = false,
  maxUnitLimit = 2,
  allowedUnits = { years: "year", months: "month", days: "day", hours: "hour", minutes: "minute" },
) => {
  let temp = "";

  let unitCount = 0;
  for (const [key, value] of Object.entries(allowedUnits)) {
    let unit = key;
    let unitString = value;

    if (unitCount < maxUnitLimit) {
      let diffValue = moment.duration(minutes, "minutes")[unit]();

      if (diffValue) {
        temp += unitCount ? " " : "";
        temp += `${diffValue} ${unitString}${usePluralConversion && diffValue > 1 ? "s" : ""}`;

        unitCount++;
      }
    } else {
      break;
    }
  }

  return temp;
};

export const getMailStatus = (status) => {
  const { USER_ACTIVE, USER_INACTIVE } = UserStatus;
  const { id, name } = status;
  return [USER_ACTIVE, USER_INACTIVE].includes(id) ? "Active" : name;
};

export const getUrlParams = (url, paramName) => {
  const tempUrl = new URL(url);

  return tempUrl.searchParams.get(paramName);
};

export const validURL = (str) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const getDurationText = (duration) => {
  if (duration < 60) {
    return `${duration} mins`;
  } else {
    let hours = parseInt(duration / 60);
    let mins = duration % 60;
    return `${hours} hr` + (mins ? ` ${mins} mins` : "");
  }
};

export const getPaginatedSerialNo = (pagination, currIdx) => {
  const { page, limit } = pagination;

  const baseSerialNo = page ? (page - 1) * limit : 0;
  const serialNo = baseSerialNo + (currIdx + 1); //Assuming Idx start with 0

  return `${serialNo < 10 ? "0" : ""}${serialNo}`;
};

export const getModulefromPath = (pathname) => {
  return pathname.split("/")[1];
};

// Parameter should be an array
export const getParentEmailFormObject = (parentEmails, input1Name, input2Name) => {
  const tempPEmails = { [input1Name]: "", [input2Name]: "" };

  parentEmails?.forEach((pe, idx) => {
    tempPEmails[idx == 0 ? input1Name : input2Name] = pe.email;
  });

  return tempPEmails;
};

// Parameter should be an object
export const getParentEmailArray = (parentEmails) => {
  const tempArr = [];
  for (const key in parentEmails) {
    if (Object.hasOwnProperty.call(parentEmails, key)) {
      const email = parentEmails[key];
      if (email) {
        tempArr.push(email);
      }
    }
  }

  return tempArr;
};

// string2 can be array or string only
export const stringConcat = (string1, string2, seperator = " ") => {
  let string = "";

  if (string1) {
    string = string1;
  }

  if (Array.isArray(string2)) {
    string2.forEach((str) => {
      if (str) {
        string += string ? `${seperator + str}` : str;
      }
    });
  } else if (typeof string2 === "string") {
    string += string ? `${seperator + string2}` : string2;
  }

  return string;
};

export const getInstituteSession = () => {
  return moment().month() < 2
    ? `${moment().subtract(1, "y").year()}-${moment().format("YY")}`
    : `${moment().year()}-${moment().add(1, "y").format("YY")}`;
};
