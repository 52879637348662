import moment from "moment";
import { Users } from "./Users";

const {
  ADMIN,
  SUPER_TEACHER,
  INSTITUTE_ADMIN,
  SUB_ROLE,
  INSTITUTE_REPORT_VIEWER,
  INSTITUTE_TEACHER,
  TEACHER,
  STUDENT,
} = Users;

export const SuperTeacherTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "First Name", key: "fname", sequence: 2 },
  { title: "Last Name", key: "lname", sequence: 3 },
  { title: "Email", key: "email", sequence: 4 },
  { title: "Phone", key: "phone", sequence: 5 },
  { title: "Created on", key: "createdAt", sequence: 6 },
  { title: "Mail Status", key: "user.status.name", sequence: 7 },
  { title: "Status", key: "status", sequence: 8 },
  { title: "Resend", sequence: 9 },
  { title: "Action", sequence: 10 },
];

export const InstituteTableHeaders = [
  { title: "ID", key: "id", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Acronym", key: "acronym", sequence: 3 },
  { title: "Address", key: "address.address", sequence: 4 },
  { title: "Pin Code", key: "address.pincode", sequence: 5 },
  { title: "Email", key: "user.email", sequence: 6 },
  { title: "Created on", key: "createdAt", sequence: 7 },
  { title: "Mail Status", key: "user.status.name.", sequence: 8 },
  { title: "Status", key: "status", sequence: 9 },
  { title: "Resend", key: "resend", sequence: 10 },
  { title: "Action", key: "action", sequence: 11 },
];

export const SubRoleTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "First Name", key: "fname", sequence: 2 },
  { title: "Last Name", key: "lname", sequence: 3 },
  { title: "Email", key: "email", sequence: 4 },
  { title: "Phone", key: "phone", sequence: 5 },
  { title: "Status", key: "status", sequence: 6 },
  { title: "Resend", sequence: 7 },
  { title: "Action", sequence: 8 },
];

export const TeacherTableHeaders = [
  { title: "ID", key: "id", sequence: 1 },
  { title: "First Name", key: "fname", sequence: 2 },
  { title: "Last Name", key: "lname", sequence: 3 },
  { title: "Email", key: "email", sequence: 4 },
  { title: "Phone", key: "phone", sequence: 5 },
  { title: "Created on", key: "createdAt", sequence: 6 },
  { title: "Email Status", key: "status.name", sequence: 7 },
  { title: "Status", key: "status", sequence: 8 },
  { title: "Resend", sequence: 9 },
  { title: "Action", sequence: 10 },
];

export const StudentTableHeaders = [
  { title: "ID", key: "id", sequence: 1, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "First Name", key: "fname", sequence: 2, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Last Name", key: "lname", sequence: 3, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Email", key: "email", sequence: 4, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Phone", key: "phone", sequence: 5, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Institute", key: "institite", sequence: 6, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Created on", key: "createdAt", sequence: 7, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Mail Status", key: "status.name", sequence: 8, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Status", key: "status", sequence: 9, roleIds: [ADMIN, SUPER_TEACHER] },
  { title: "Resend", sequence: 10, roleIds: [ADMIN] },
  { title: "Action", sequence: 11, roleIds: [ADMIN] },
];

export const AuthorTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Description", key: "description", sequence: 3 },
  { title: "Action", sequence: 4 },
];

export const QuestionCategoryTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Lower Value", key: "lowerValue", sequence: 3 },
  { title: "Higher Value", key: "higherValue", sequence: 4 },
  { title: "Factor", key: "factor", sequence: 5 },
  { title: "Action", sequence: 6 },
];

export const InstituteLeadTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "First Name", key: "fname", sequence: 2 },
  { title: "Last Name", key: "lname", sequence: 3 },
  { title: "Acronym", key: "acronym", sequence: 4 },
  { title: "Email", key: "email", sequence: 5 },
  { title: "Phone", key: "phone", sequence: 6 },
  { title: "Address", key: "address", sequence: 7 },
  { title: "Pin Code", key: "pincode", sequence: 8 },
  { title: "Created on", key: "createdAt", sequence: 9 },
  { title: "Status", key: "status", sequence: 10 },
  { title: "Action", key: "action", sequence: 11 },
];

export const BoardTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Classes", key: "classes", sequence: 3 },
  { title: "Action", key: "action", sequence: 4 },
];

export const ClassTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Roman Name", key: "romanName", sequence: 3 },
  { title: "Sequence", key: "sequence", sequence: 4 },
  { title: "Subjects", key: "subjects", sequence: 5 },
  { title: "Action", key: "action", sequence: 6 },
];

export const SubjectTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Color Icon", key: "image1Url", sequence: 3 },
  { title: "B/W Icon", key: "image2url", sequence: 4 },
  { title: "Chapters", key: "chapters", sequence: 5 },
  { title: "Action", key: "action", sequence: 6 },
];

export const ChapterTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Weightage", key: "weightage", sequence: 3 },
  { title: "Sequence", key: "sequence", sequence: 4 },
  { title: "Chapters", key: "chapters", sequence: 5 },
  { title: "Action", key: "action", sequence: 6 },
];

export const TopicTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "name", sequence: 2 },
  { title: "Action", key: "action", sequence: 3 },
];

export const KeywordTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Board", key: "board", sequence: 2 },
  { title: "Class", key: "class", sequence: 3 },
  { title: "Subject", key: "subject", sequence: 4 },
  { title: "Chapter", key: "chapter", sequence: 5 },
  { title: "Name", key: "name", sequence: 6 },
  { title: "Action", key: "action", sequence: 7 },
];

export const InstituteTeacherTableHeaders = [
  { title: "S. No.", key: "", sequence: 1 },
  { title: "Name", key: "board", sequence: 2 },
  { title: "Email", key: "class", sequence: 3 },
  { title: "Class & Section", key: "subject", sequence: 4 },
  { title: "Test creation allowed for", key: "chapter", sequence: 5 },
  { title: "Actions", key: "action", sequence: 7 },
];

export const InstitutePendingTeacherTableHeaders = [
  { title: "Select all", key: "", checkbox: true },
  { title: "Name", key: "board" },
  { title: "Email", key: "class" },
  { title: "Resend email", key: "subject" },
  { title: "Actions", key: "action" },
];

export const InstituteStudentTableHeaders = [
  { title: "Select all", key: "", checkbox: true },
  { title: "UID", key: "uid" },
  { title: "Student", key: "student" },
  { title: "Email & Contact no.", key: "contact" },
  { title: "Parent's", key: "parentEmails" },
  { title: "Class & Section", key: "classSection" },
  { title: "Groups", key: "groups" },
  { title: "Actions", key: "action" },
];

export const InstitutePendingStudentTableHeaders = [
  { title: "Select all", key: "", checkbox: true },
  { title: "Name", key: "name" },
  { title: "Email", key: "eamilk" },
  { title: "Account created", key: "createdAt" },
  // { title: "Last email sent", key: "emailSentAt" },
  { title: "Resend invite email", key: "resendEmail" },
  { title: "Actions", key: "action" },
];

export const InstituteReportViewerTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "Name", key: "name" },
  { title: "Email", key: "email" },
  { title: "Actions", key: "action" },
];

export const InstitutePendingReportViewerTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "Name", key: "name" },
  { title: "Email", key: "email" },
  { title: "Resend email", key: "resend" },
  { title: "Actions", key: "action" },
];

export const InstituteTeacherPreviewTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "First name", key: "name" },
  { title: "Last name", key: "email" },
  { title: "Email", key: "resend" },
  { title: "Phone", key: "action" },
];

export const InstituteStudentPreviewTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "UID", key: "uid" },
  { title: "First name", key: "name" },
  { title: "Last name", key: "email" },
  { title: "Email", key: "resend" },
  { title: "Phone", key: "phone" },
  { title: "Class", key: "class" },
  { title: "Section", key: "section" },
  { title: "Parent emails", key: "parentEmails" },
];

export const IndependentTeacherStudentTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "Student", key: "student" },
  { title: "Parent's", key: "parentEmails" },
  { title: "Added on", key: "createdAt" },
  { title: "Class", key: "class" },
  { title: "Groups", key: "groups" },
  { title: "Actions", key: "action" },
];

export const IndependentTeacherPendingStudentTableHeaders = [
  { title: "Select all", key: "", checkbox: true },
  { title: "S. No.", key: "sno" },
  { title: "Name", key: "name" },
  { title: "Class", key: "class" },
  { title: "Joining request date", key: "requestedAt" },
  { title: "Approve request", key: "approveRequest" },
  { title: "Actions", key: "action" },
];

export const SuperTeacherSubRoleTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "Name", key: "name" },
  { title: "Email & Contact no.", key: "email" },
  { title: "Classes and Subjects", key: "class" },
  { title: "Actions", key: "action" },
];

export const InstituteTeacherStudentTableHeaders = [
  { title: "S. No.", key: "" },
  { title: "UID", key: "uid" },
  { title: "Student", key: "student" },
  { title: "Email", key: "email" },
  { title: "Parent's", key: "parentEmails" },
  { title: "Class & Section", key: "classSection" },
  { title: "Groups", key: "groups" },
  { title: "", key: "action" },
];

export const InstituteClassSectionTableHeaders = [
  { title: "S. No.", key: "sno" },
  { title: "Class", key: "class" },
  { title: "Section", key: "section" },
  { title: "Number of students", key: "students" },
  { title: "", key: "action" },
];

export const RatingName = { 1: "Bad", 2: "Not good", 3: "Good", 4: "Very Good", 5: "Excellent" };

export const ContentStatus = {
  CONT_SAVED: 100,
  CONT_CREATED: 400,
  CONT_APPROVE_PENDING: 600,
  CONT_REJECTED: 700,
  CONT_ACTIVE: 800,
};

export const ContentAccess = { CA_INSTITUTE: 100, CA_GLOBAL_REQ: 200, CA_GLOBAL: 300 };

export const ContentType = { PDF: 100, VIDEO: 200 };

export const ContentTypeList = [
  { id: 100, name: "Books" },
  { id: 200, name: "Videos" },
];

export const UserStatus = {
  USER_INVITED: 100,
  USER_ONBOARDING: 150,
  USER_ACTIVE: 200,
  USER_INACTIVE: 300,
};

export const InstituteStatus = {
  INSTITUTE_ACTIVE: 100,
  INSTITUTE_INACTIVE: 200,
};

export const InstituteLeadStatus = {
  INST_LEAD_PENDING: 100,
  INST_LEAD_APPROVED: 200,
  INST_LEAD_REJECTED: 300,
};

export const InstituteUserStatus = { INST_USER_PENDING: 100, INST_USER_JOINED: 200 };

export const StudentOnboardingStatus = {
  STD_CREATED: 100,
  STD_CLASS_ADDED: 200,
  STD_PARENT_CONSENT_PENDING: 300,
  STD_ONBOARDED: 400,
};

export const DoubtStatus = { DOUBT_PENDING: 100, DOUBT_ANSWERED: 200, DOUBT_REJECTED: 300 };

export const TestTypeList = [
  { id: 100, name: "General" },
  { id: 200, name: "Time bound" },
  { id: 300, name: "Interactive" },
];

export const TestType = {
  TEST_TYP_MASTERY: 200,
  TEST_TYP_MY_TEST: 300,
  TEST_TYP_GENERAL: 400,
  TEST_TYP_TIME_BOUND: 500,
  TEST_TYP_INTERACTIVE: 600,
  TEST_TYP_PIP: 700,
};

export const TestForList = [
  { id: 10, name: "Group" },
  { id: 20, name: "Students" },
  { id: 30, name: "Classes" },
];

export const TestFor = {
  TEST_FOR_GRP: { id: 10, name: "Group" },
  TEST_FOR_STD: { id: 20, name: "Individual Students" },
  TEST_FOR_CLASS: { id: 30, name: "Classes" },
};

export const TestStatus = {
  TEST_SAVED: 100,
  TEST_SCHEDULED: 200,
  TEST_ONGOING: 300,
  TEST_COMPLETED: 400,
};

export const ApplicantTestStatus = {
  APPLICANT_NOT_JOINED: 100,
  APPLICANT_JOINED: 200,
  APPLICANT_STARTED: 300,
  APPLICANT_SUBMITTED: 400,
};

export const InteractiveTestQuesAnsStatus = {
  STD_NOT_JOINED: 100,
  STD_JOINED: 200,
  STD_STARTED: 300,
  STD_COMPLETED: 400,
};

export const InteractiveTestQues = {
  NOT_STARTED: 100,
  STARTED: 200,
  ANSWER_CLOSED: 300,
  COMPLETED: 400,
};

export const InteractiveTestWhiteboardStatus = {
  WBOARD_NOT_SHARED: 100,
  WBOARD_EXPLANATION_SHARED: 200,
  WBOARD_CONTENT_SHARED: 300,
};

export const TestReportStatus = {
  REPORT_PENDING: 100,
  REPORT_NOT_CREATED: 200,
  REPORT_CREATED_WEB: 300,
  REPORT_CREATED_PDF: 400,
};

export const LicenseStatus = {
  LIC_FREE: 100,
  LIC_TRIAL: 200,
  LIC_CUSTOM: 300,
  LIC_PAID: 400,
};

export const LicensePaymentStatus = {
  LPAY_CREATED: 100,
  LPAY_PROCESSING: 200,
  LPAY_SUCCESS: 300,
  LPAY_FAILED: 400,
  LPAY_CANCELED: 500,
};

export const TestDurations = {
  questionDur: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  testDur: [5, 10, 20, 30, 45, 60, 75, 90, 120, 150, 180],
};

export const BooleanFilterIds = {
  BOOL_FILTER_TRUE: 100,
  BOOL_FILTER_FALSE: 200,
};

export const ValueComparisionList = [
  { id: "gt", name: "Greater than" },
  { id: "eq", name: "Equal to" },
  { id: "lt", name: "Less than" },
];

export const PIPTestStatus = {
  PIP_TEST_NOT_APPLICABLE: 100,
  PIP_TEST_NOT_YET_ATTEMPTED: 200,
  PIP_TEST_COMPLETED: 300,
  PIP_TEST_NOT_ATTEMPTED: 400,
};

export const FeedCards = {
  STD_WELCOME_CARD: 100,
  STD_SCHOOL_INVITE_CARD: 200,
  STD_TEST_ONGOING_CARD: 300,
  STD_TEST_SUBMITTED_CARD: 400,
  STD_TEST_RESULT_CARD: 500,
  STD_DOUBT_ANSWERED_CARD: 600,
  STD_LICENSE_TRIAL: 700,
  STD_LICENSE_TRIAL_END: 800,
  STD_LICENSE_SUBSCRIPTION: 900,
  STD_LICENSE_SCHOOL: 1000,
  STD_LICENSE_ENDED: 1100,
};

export const DoubtsPendingForList = [
  {
    id: 100,
    name: "3 days to 1 Week",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().subtract(3, "days").format("YYYY-MM-DD"),
  },
  {
    id: 200,
    name: "1 Week or more",
    fromDate: moment("1970-01-01").format("YYYY-MM-DD"),
    toDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
  },
];
