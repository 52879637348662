import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import { StudentOnboardingStatus } from "../Utils/constants";

export default function PrivateRoute({ component: Component, title = "", allowedRoles, ...rest }) {
  document.title = `${title ? title + " | " : ""}${process.env.REACT_APP_WEBSITE_NAME}`;

  const { currentUser, roleId, stdDetails } = useAuth();

  // To handle student onboarding & blocking other pages before successful onboarding
  const { onboardingStatus } = stdDetails || {};
  const { id: stdOnboardingStatusId } = onboardingStatus || {};
  const { STD_CREATED, STD_CLASS_ADDED, STD_PARENT_CONSENT_PENDING } = StudentOnboardingStatus;

  const isOnboard = useRouteMatch(`/onboard`);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          //not logged in, so redirect to login
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if (roleId.length > 0) {
          // If stdudent is not onboared then onboarding pages will only be accessible to student
          if (
            stdOnboardingStatusId &&
            [STD_CREATED, STD_CLASS_ADDED, STD_PARENT_CONSENT_PENDING].includes(
              stdOnboardingStatusId,
            )
          ) {
            if (isOnboard) {
              return <Component />;
            }

            return <Redirect to="/onboard" />;
          }

          // if allowedRoles is true, route is restricted by role
          // Blocking onboarding student related pages after successful onboading
          if (allowedRoles?.indexOf(roleId[0]) === -1 || isOnboard) {
            //if true, role not authorized so redirect to home
            return <Redirect to="/" />;
          }

          //authorized so return component
          return <Component {...props} />;
        }
      }}
    ></Route>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
};
