import axios from "Utils/axios";

class Profile {
  static getProfile() {
    return axios.get(`/me/profile`);
  }

  static updateProfile(data) {
    return axios.put(`/me/profile`, data);
  }

  static updateConfigs(data) {
    return axios.put(`/me/configs`, data);
  }

  static getInstituteStatistics() {
    return axios.get(`/me/institutes/statistics`);
  }

  static updateInstituteDetails(data) {
    return axios.put(`/me/institutes`, data);
  }
}

export default Profile;
