import React, { createContext, useContext, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUrlParams } from "../Utils/common";

const ModuleContext = createContext();

export function useModuleContext() {
  return useContext(ModuleContext);
}

/*
For subModule:
  moduleName: moduleName/subModuleName
For sibling module(simillar module):
  modulName: moduleName(sibling moduleName)
*/
const generateModuleName = (pathname) => {
  const temp = pathname.split("/");

  switch (temp[1]) {
    case "library":
      if (temp.length > 2) {
        const libraryModules = [
          "institute-content",
          "my-content",
          "approve-content",
          "approve-global-content",
          "reported-content",
        ];

        return libraryModules.includes(temp[2])
          ? `${temp[1]}(${temp[2]})`
          : `${temp[1]}/chapterContent`;
      }

      return "library";

    case "doubts":
      return `${temp[1]}(${temp[2]})`;

    default:
      return temp[1];
  }
};

const handleMCQsFilterChange = (field, payload) => {
  switch (field) {
    case "classId":
      return { classId: payload, subjectId: "", chapterId: "", topicId: "", activePage: 1 };
    case "subjectId":
      return { subjectId: payload, chapterId: "", topicId: "", activePage: 1 };
    case "chapterId":
      return { chapterId: payload, topicId: "", activePage: 1 };
    case "topicId":
      return { topicId: payload, activePage: 1 };
    case "searchKey":
      return { searchKey: payload, activePage: 1 };
    case "questionCategoryId":
      return { questionCategoryId: payload, activePage: 1 };
    case "hasQuesImageId":
      return { hasQuesImageId: payload, activePage: 1 };
    case "attemptCount":
      return { attemptCount: payload, activePage: 1 };
    case "hasExplanationId":
      return { hasExplanationId: payload, activePage: 1 };
    case "correctRate":
      return { correctRate: payload, activePage: 1 };
    case "createdAt":
      return { createdAt: payload, activePage: 1 };
    case "sourceId":
      return { sourceId: payload, activePage: 1 };
    case "questionId":
      return { questionId: payload, activePage: 1 };
  }
};

const handleLibraryTabsFilterChange = (field, payload) => {
  switch (field) {
    case "classId":
      return { classId: payload, subjectId: "", chapterId: "", activePage: 1 };
    case "subjectId":
      return { subjectId: payload, chapterId: "", activePage: 1 };
    case "chapterId":
      return { chapterId: payload, activePage: 1 };
    case "searchKey":
      return { searchKey: payload, activePage: 1 };
    case "teacherId":
      return { teacherId: payload, activePage: 1 };
    case "contentTypeId":
      return { contentTypeId: payload, activePage: 1 };
    case "approvalId":
      return { approvalId: payload, activePage: 1 };

    // These data is used in Library panel breadcrump
    case "classData":
      return { classData: payload, subjectData: {} };
    case "subjectData":
      return { subjectData: payload };
  }
};

const handleChapterContentFilterChange = (field, payload) => {
  switch (field) {
    case "sortBy":
      switch (payload) {
        case "Newest":
          return { sortBy: "Newest", orderBy: "createdAt", orderType: "DESC" };
        case "Oldest":
          return { sortBy: "Oldest", orderBy: "createdAt", orderType: "ASC" };
        case "Highest Rated":
          return { sortBy: "Highest Rated", orderBy: "rating", orderType: "DESC" };
        case "Lowest Rated":
          return { sortBy: "Lowest Rated", orderBy: "rating", orderType: "ASC" };
      }
  }
};

const handleDoubtFilterChange = (field, payload) => {
  switch (field) {
    case "classId":
      return { classId: payload, subjectId: "", chapterId: "", pagination: {} };
    case "subjectId":
      return { subjectId: payload, chapterId: "", pagination: {} };
    case "chapterId":
      return { chapterId: payload, pagination: {} };
    case "searchKey":
      return { searchKey: payload, pagination: {} };
    case "teacherId":
      return { teacherId: payload, pagination: {} };
    case "pendingForId":
      return { pendingForId: payload, pagination: {} };
  }
};

const getModuleInitalStates = (module, submoduleKey) => {
  switch (module) {
    case "test":
      return { teacherId: getUrlParams(window.location.href, "teacherId") || "", activePage: 1 };

    case "mcq":
      return {
        activePage: 1,
        classId: "",
        subjectId: "",
        chapterId: "",
        topicId: "",
        searchKey: "",
        questionCategoryId: "",
        hasQuesImageId: "",
        attemptCount: null,
        hasExplanationId: "",
        correctRate: null,
        createdAt: null,
        sourceId: "",
        questionId: "",
      };

    case "reported-mcq":
      return {
        activePage: 1,
        classId: "",
        subjectId: "",
        searchKey: "",
      };

    case "library":
      const chapterContentInits = {
        topic: {},
        typeId: 200,
        sortBy: "Newest",
        orderBy: "createdAt",
        orderType: "DESC",
      };

      if (submoduleKey == "chapterContent") {
        return chapterContentInits;
      }

      return {
        classId: "",
        subjectId: "",
        searchKey: "",
        activePage: 1,
        showContentSearch: false,
        chapterContent: chapterContentInits,
      };

    case "library(institute-content)":
      return {
        classId: "",
        subjectId: "",
        searchKey: "",
        teacherId: getUrlParams(window.location.href, "teacherId") || "",
        contentTypeId: getUrlParams(window.location.href, "typeId") || "",
        activePage: 1,
      };

    case "library(approve-content)":
      return { classId: "", subjectId: "", searchKey: "", activePage: 1, teacherId: "" };

    case "library(my-content)":
      return {
        classId: "",
        subjectId: "",
        chapterId: "",
        searchKey: "",
        activePage: 1,
        approvalId: "",
        contentTypeId: getUrlParams(window.location.href, "typeId") || "",
      };

    case "library(approve-global-content)":
      return {
        classId: "",
        subjectId: "",
        searchKey: "",
        activePage: 1,
      };

    case "library(reported-content)":
      return {
        classId: "",
        subjectId: "",
        searchKey: "",
        activePage: 1,
      };

    case "doubts(pending)":
      return {
        doubts: "",
        classId: "",
        subjectId: "",
        chapterId: "",
        pendingForId: "",
        searchKey: "",
        pagination: {},
      };

    case "doubts(institute-answers)":
      return {
        doubts: "",
        classId: "",
        subjectId: "",
        chapterId: "",
        teacherId: getUrlParams(window.location.href, "teacherId"),
        searchKey: "",
        pagination: {},
      };

    case "doubts(my-answers)":
      return {
        doubts: "",
        classId: "",
        subjectId: "",
        chapterId: "",
        searchKey: "",
        pagination: {},
      };

    case "doubts(all-answers)":
      return {
        doubts: "",
        classId: "",
        subjectId: "",
        chapterId: "",
        searchKey: "",
        pagination: {},
      };

    default:
      return {};
  }
};

function reducer(state, action) {
  let filterData, subModState;

  switch (action.type) {
    case "MCQsFilter":
      filterData = handleMCQsFilterChange(action.fieldName, action.payload);

      return { ...state, ...filterData };

    case "libraryFilter":
      filterData = handleLibraryTabsFilterChange(action.fieldName, action.payload);

      return { ...state, ...filterData };

    case "chapterContentFilter":
      filterData = handleChapterContentFilterChange(action.fieldName, action.payload);
      subModState = { ...state[action.subModKey], ...filterData };

      return { ...state, [action.subModKey]: subModState };

    case "field":
      return { ...state, [action.fieldName]: action.payload };

    case "subModuleField":
      subModState = { ...state[action.subModKey], [action.fieldName]: action.payload };

      return { ...state, [action.subModKey]: subModState };

    case "subModuleReset":
      const tempState = getModuleInitalStates(action.module, action.moduleKey);

      return { ...state, [action.moduleKey]: tempState };

    case "testDataReset":
      const init = getModuleInitalStates(action.module);
      const { teacherId } = state;

      return { ...init, teacherId };

    case "doubtsFilter":
      filterData = handleDoubtFilterChange(action.fieldName, action.payload);

      return { ...state, ...filterData };

    case "reset":
      return getModuleInitalStates(action.module);
  }
}

export function ModuleStateProvider({ children }) {
  const location = useLocation(); //This hook called when url changes
  const currModule = generateModuleName(location.pathname);

  const [moduleState, dispatchModuleState] = useReducer(reducer, {});
  const [module, setModule] = useState("");

  const resetModuleState = () => {
    dispatchModuleState({ type: "reset", module });
  };

  // Resetting module data on route change
  if (currModule != module) {
    // Checking current or previous module has subModule or not...
    const tCurrModule = currModule.split("/");
    const tModule = module.split("/");

    if (
      Object.keys(moduleState).length && // Handling submodule page refresh. Submodule state should be reset only if module state available
      tCurrModule[0] == tModule[0] &&
      (tCurrModule.length == 2 || tModule.length == 2)
    ) {
      tModule.length == 2 &&
        dispatchModuleState({
          type: "subModuleReset",
          module: tModule[0],
          moduleKey: tModule[1],
        });
      setModule(currModule);
    } else if (tModule[0] != tCurrModule[0]) {
      dispatchModuleState({ type: "reset", module: tCurrModule[0] });
      setModule(currModule);
    }
  }

  /* SetData Passed Data format: 
    For Module reset, {type: "reset", module: "moduleName"},
    For Submodule(or nested submoodule) reset, {type : "submoduleReset", module: "moduleName", moduleKey: "moduleKeyname"}
    For storing data, {type: "field", fieldName: "fieldName", payload: "payload"}
    */
  const value = {
    moduleState,
    dispatchModuleState,
    resetModuleState,
  };

  return <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>;
}
