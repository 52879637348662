import React, { useContext, useReducer } from "react";
import PropTypes from "prop-types";

const StepContext = React.createContext();

export function useStepState() {
  return useContext(StepContext);
}

function reducer(state, action) {
  switch (action.type) {
    case "classId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        subjectId: "",
        chapterId: "",
        topicId: "",
        subjects: [],
        chapters: [],
        topics: [],
      };
    }
    case "subjectId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        chapterId: "",
        topicId: "",
        chapters: [],
        topics: [],
      };
    }
    case "chapterId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
        topicId: "",
        topics: [],
      };
    }
    case "topicId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "authorId": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "resource": {
      return {
        ...state,
        [action.resourceName]: action.payload,
      };
    }
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "file": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }

    case "resetContent": {
      return {
        ...state,
        ...initialState,
        keywordIds: [],
        [action.fieldName]: action.payload,
      };
    }

    case "reset": {
      return {
        ...filterInitialState,
        ...initialState,
        keywordIds: [],
        [action.fieldName]: action.payload,
      };
    }
  }
}

const filterInitialState = {
  classId: "",
  subjectId: "",
  chapterId: "",
  topicId: "",
  authorId: "",
  classes: [],
  subjects: [],
  chapters: [],
  topics: [],
  authors: [],
};

const initialState = {
  youtubeUrl: "",
  name: "",
  description: "",
  videoFile: null,
  pdfFile: null,
  imageFile: null,
  contentId: null,
  keywords: [],
  keywordIds: [],
  accessId: 200,
  uploading: false,
  uploadPercentage: 0,
  type: "default",
  show: "video",
  success: false,
  disabled: false,
};

export function StepProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, { ...filterInitialState, ...initialState });

  const value = {
    state,
    dispatch,
  };
  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
}

StepProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
