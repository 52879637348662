import React, { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, Redirect } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import { AuthProvider } from "Contexts/AuthContext";
import { StepProvider } from "Contexts/StepContext";
import { FilterProvider } from "Contexts/FilterContext";
import ErrorFallback from "Components/ErrorFallback";
import PrivateRoute from "Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import { ModuleStateProvider } from "./Contexts/ModuleContext";
import { AddEditTestProvider } from "./Contexts/AddEditTestContext";

const Login = lazy(() => import("Pages/Auth/Login"));
const ForgotPassword = lazy(() => import("Pages/Auth/ForgotPassword"));
const RoleRoute = lazy(() => import("Routes/RoleRoute"));
const PublicTest = lazy(() => import("Pages/Test/Student/PublicTest"));
const ResetPassword = lazy(() => import("Pages/Auth/EmailLinkAuth/ResetPassword/ResetPassword"));
const InstituteInvitation = lazy(() => import("Pages/Auth/EmailLinkAuth/InstituteInvitation"));
const ParentConsent = lazy(() => import("Pages/Auth/EmailLinkAuth/ParentConsent"));

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={
          <Flex align="center" justifyContent="center" className="h-screen">
            <Spinner size="xl" />
          </Flex>
        }
      >
        <AuthProvider>
          <StepProvider>
            <FilterProvider>
              <ModuleStateProvider>
                <AddEditTestProvider>
                  <Switch>
                    {/* Use PrivateRoute for pages which is only accessible for loggedin user */}
                    {/* Use PublicRoute for pages which is only accessible for non-loggedin user */}
                    {/* Use Route for pages which can be accessible for all */}

                    <PublicRoute path="/login" title="Login" component={Login} />
                    <PublicRoute
                      exact
                      path="/forgot-password"
                      title="Forgot password"
                      component={ForgotPassword}
                    />
                    <PublicRoute
                      exact
                      path="/p/t/:shareCode"
                      title="Live test"
                      component={PublicTest}
                    />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/institute-invitation" component={InstituteInvitation} />
                    <Route exact path="/account-activated" component={InstituteInvitation} />
                    <Route exact path="/parent-consent" component={ParentConsent} />
                    <PrivateRoute path="/" component={RoleRoute} />
                    <Route path="*" component={() => <Redirect to="/" />} />
                  </Switch>
                </AddEditTestProvider>
              </ModuleStateProvider>
            </FilterProvider>
          </StepProvider>
        </AuthProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
